<template>
  <div>
    <!-- Counter widget -->
    <a-card :bordered="false" class="bg">
      <!-- PERFIL E CARTEIRA -->
      <div class="profile">
        <div class="info">
          <h5>{{ name }}</h5>
          <p>{{ federalId }}</p>
        </div>
        <div class="avatar">
          <a-avatar :size="64" icon="user" />
        </div>
      </div>
    </a-card>

    <a-card :bordered="false" class="widget-1 bg">
      <a-statistic :title="title" :value="maskedValue" :prefix="prefix">
      </a-statistic>
      <div class="icon" @click="toggleMask">
        <svg
          v-if="isMasked"
          width="20"
          height="20"
          fill="none"
          stroke="#141414"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94"
          ></path>
          <path d="M14.12 14.12a3 3 0 1 1-4.24-4.24"></path>
          <path d="m1 1 22 22"></path>
          <path
            d="M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19"
          ></path>
        </svg>
        <svg
          v-else
          width="20"
          height="20"
          fill="none"
          stroke="#141414"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
          <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z"></path>
        </svg>
      </div>
    </a-card>
    <!-- / Counter widget -->
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
    prefix: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    federalId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isMasked: false,
    };
  },
  computed: {
    maskedValue() {
      return this.isMasked
        ? "*".repeat(this.value.toString().length)
        : this.value;
    },
  },
  methods: {
    toggleMask() {
      this.isMasked = !this.isMasked;
    },
  },
};
</script>

<style  lang="scss" scoped>
.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .info {
    width: 70%;

    h5 {
      margin: 0;
      padding: 0;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
}

.icon {
  background: none;
  margin-top: 30px;
  box-shadow: none;
  width: 10%;
  cursor: pointer;
}

.bg {
  background: none;
  box-shadow: none;
}
</style>
